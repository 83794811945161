import { FC, useMemo } from 'react';
import { IProps } from './interfaces';
import Stack from '../../../../Stack/Stack';
import UniversalSelect from '../../../../Selects/UniversalSelect/UniversalSelect';
import { DS } from '../../../../../constants/constants';
import useTranslation from '../../../../../hooks/useTranslation/useTranslation';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { changeOptions } from '../../../../UserSettings/reducer';
import { useAppDispatch } from '../../../../../hooks/useSettings';
import { generalReducerValues } from '../../../../../General.reducer';
import { useSelector } from 'react-redux';
import { getObjectName } from '../../../../../hooks/useObjectTranslation';
import { IChangeOptionsArgs } from '../../../../UserSettings/interfaces';
import { useSendSimpleAnalytics } from '../../../../../hooks/useAnalytics';
import DetailSelect from '../../../../Selects/DetailSelect/DetailSelect';
import getChartDataDetailing from '../../../../../constants/chartDetailing';
import { detailOptions } from '../../../../../constants/detailOptions';
import { SelectWrapper } from './styles';
import { isUndefined } from 'lodash';
import { TSelectedOptions } from '../../../../Selects/Select/interfaces';


const EXCLUDE_SELECT_OBJECT_FOR = [
    'objects',
    'pos_metrics',
];
/**
 * Селекты виджета
 */
const Selects: FC<IProps> = (props) => {
    const {
        cfg: { reportingObjectsById },
        allMetrics,
    } = useSelector(generalReducerValues);
    const sendAnalytics = useSendSimpleAnalytics();

    const localCurrentOptions = useWidgetCurrentOptions(props.moduleId);

    const { t } = useTranslation(['metrics', 'translation']);
    const dispatch = useAppDispatch();

    const onMetricChange = (args: IChangeOptionsArgs) => {
        const metricSection = allMetrics?.find(
            (element) =>
                element.id ===
                (args.newOptions?.[`metricSelect${DS}${props.moduleId}${DS}${props.widgetId}`] as TSelectedOptions)?.[0]
                    ?.id,
        )?.section;

        metricSection &&
            // sendAnalytics(`${'groupOfMetrics'}_${metricSection}_selected`, 'reachGoal', `${props.moduleId}`);
            dispatch(changeOptions(args));
    };

    /** Запрещенная детализация */
    const restrictedDetailSelectOptions = useMemo(() => {
        const availableDetailingIds = getChartDataDetailing(t, [], undefined, props.mainPeriod).map(
            (detail) => detail.id,
        );
        return detailOptions.filter((detail) => !availableDetailingIds.includes(detail.id)).map((detail) => detail.id);
    }, [props.mainPeriod, t]);

    return (
        <Stack flexWrap={'wrap'} gap={15}>
            {(EXCLUDE_SELECT_OBJECT_FOR.includes(props.chartType) === false) && (
                <SelectWrapper
                    visible={
                        isUndefined(props.viewSettings?.showObjectSelect)
                            ? true
                            : Boolean(props.viewSettings?.showObjectSelect)
                    }
                >
                    <UniversalSelect
                        options={props.reportingObjects.map((item) => ({
                            text: getObjectName({ reportingObject: reportingObjectsById[item.id], t, showType: true }),
                            id: item.id,
                        }))}
                        name={`objectSelect${DS}${props.moduleId}${DS}${props.widgetId}`}
                        changeOptions={(args) => dispatch(changeOptions(args))}
                        localCurrentOptions={localCurrentOptions}
                        labelText={t('Select object')}
                        mode="single"
                    />
                </SelectWrapper>
            )}
            {props.chartType !== 'metrics' && (
                <SelectWrapper
                    visible={
                        isUndefined(props.viewSettings?.showMetricsSelect)
                            ? true
                            : Boolean(props.viewSettings?.showMetricsSelect)
                    }
                >
                    <UniversalSelect
                        options={allMetrics
                            .filter((metric) => props.metrics.includes(metric.id))
                            .map((metric) => ({ id: metric.id, text: t(metric.text) }))}
                        name={`metricSelect${DS}${props.moduleId}${DS}${props.widgetId}`}
                        localCurrentOptions={localCurrentOptions}
                        changeOptions={onMetricChange}
                        labelText={t('Metrics')}
                        mode="single"
                    />
                </SelectWrapper>
            )}

            <SelectWrapper
                visible={
                    isUndefined(props.detail)
                        ? isUndefined(props.viewSettings?.showDetailsSelect)
                            ? true
                            : Boolean(props.viewSettings?.showDetailsSelect)
                        : false
                }
            >
                <DetailSelect
                    name={`detailSelect${DS}${props.moduleId}${DS}${props.widgetId}`}
                    restrictedOptions={[...(restrictedDetailSelectOptions || [])]}
                    changeOptions={(args) => dispatch(changeOptions(args))}
                    localCurrentOptions={localCurrentOptions}
                />
            </SelectWrapper>
        </Stack>
    );
};

export default Selects;
