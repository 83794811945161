import { FC, useCallback, useState } from 'react';
import { AnyAction } from 'redux';
import { ChevronDownFilled, ChevronRightFilled, InfoFilled } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';

import Stack from '../Stack/Stack';
import Tooltip from '../Tooltip/Tooltip';
import IconButton from '../IconButton/IconButton';

import PDFDownload from './components/PDFDownload/PDFDownload';
import Reload from './components/Reload/Reload';
import { XLSXDownload } from './components/XLSXDownload/XLSXDownload';
import { CSVDownload } from './components/CSVDownload/CSVDownload';
import { Wrapper } from './styles';
import FeedbackButton from './components/FeedbackButton/FeedbackButton';

interface IProps {
    widgetName: string;
    /** Данные для загрузки PDF */
    pdfDownloadData?: {
        targetRef: React.RefObject<HTMLDivElement | null>;
    };
    /** Данные для загрузки XLSX */
    xlsxDownloadData?: { tableName?: string; onDownload?: () => void };
    csvDownloadData?: { tableName?: string; onDownload?: () => void };
    /** Функция для перезагрузки виджета */
    reloadHandler?: () => AnyAction;
    /** Функция для перезагрузки виджета, но без диспатча */
    reloadHandlerNoDispatch?: () => void;
    hasFeedback?: boolean;
    hideOnMobile?: boolean;
    info?: string;
}

/**
 * Компонент для отображения дополнительных контролов для виджета
 */
const WidgetAdditionalControls: FC<IProps> = ({
    pdfDownloadData,
    reloadHandler,
    reloadHandlerNoDispatch,
    xlsxDownloadData,
    csvDownloadData,
    info,
    widgetName,
    hasFeedback = true,
    hideOnMobile = true,
}) => {
    const { t } = useTranslation(['translationHelp']);
    const [isExpanded, setIsExpanded] = useState(true);

    const handleCollapse = useCallback(
        () => {
            const widgetWrapperElement = pdfDownloadData?.targetRef.current;

            if (!widgetWrapperElement) {
                return;
            }

            setIsExpanded((isExpanded) => {
                widgetWrapperElement.style.setProperty('height', isExpanded ? '60px' : 'auto');
                const children = widgetWrapperElement.children;
                Array.from(children)
                    .filter((_, index) => (index > 0))
                    .forEach((child: HTMLElement) => (child.style.display = isExpanded ? 'none' : ''));
                return !isExpanded;
            });
        },
        [pdfDownloadData?.targetRef],
    );

    return (
        <Wrapper hideOnMobile={hideOnMobile}>
            <Stack padding={'0 0 0 5px'} gap={0}>
                {pdfDownloadData && <PDFDownload {...pdfDownloadData} fileName={widgetName} />}
                {(reloadHandler || reloadHandlerNoDispatch) && (
                    <Reload
                        reloadHandler={reloadHandler}
                        reloadHandlerNoDispatch={reloadHandlerNoDispatch}
                        widgetName={widgetName}
                    />
                )}
                {xlsxDownloadData?.onDownload && <XLSXDownload {...xlsxDownloadData} />}
                {csvDownloadData?.onDownload && <CSVDownload {...csvDownloadData} />}
                {hasFeedback && <FeedbackButton widgetName={widgetName} />}
                {info && (
                    <Tooltip content={t(info)}>
                        <IconButton
                            styles={{ paddingTop: 1 }}
                            onClick={() => {}}
                            icon={<InfoFilled />}
                            testId={`FEEDBACK-${widgetName}`}
                        />
                    </Tooltip>
                )}
                <Tooltip content={t('Toggle widget')}>
                    <IconButton
                        styles={{ paddingTop: 1 }}
                        onClick={handleCollapse}
                        icon={isExpanded ? <ChevronDownFilled /> : <ChevronRightFilled />}
                        testId={`TOGGLE_WIDGET-${widgetName}`}
                    />
                </Tooltip>
            </Stack>
        </Wrapper>
    );
};

export default WidgetAdditionalControls;

