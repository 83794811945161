import { TFunction } from 'i18next';
import { IMetric, IReportingObject, TMetricResponse } from '../../../../../../General.interfaces';
import { DS } from '../../../../../../constants/constants';
import { PAYMENT_TYPE_TEXT_MAP, PAYMENT_ANALYSIS_TENANT_SELECT } from '../../../../constants/constants';
import { IAverageByColumnId, IColumn, TCellData, TDataAdapterResponse, TTenantsData } from '../interfaces';
import { isNumber, sum } from 'lodash';
import getDifferenceBetweenNumbers from '../../../../../../tools/getDifferenceBetweenNumbers';
import { TabListItemIds } from '../../../enums';
import { currenciesMap } from '../../../../../../constants/currencies';
import { useEffect, useState } from 'react';
import { useGeneralSelector, useWidgetCurrentOptions } from 'src/hooks';
import filterReportingObjects from 'src/components/Filters/tools/filterReportingObjects';

interface IArgs {
    response: TMetricResponse[];
    reportingObjectsById: { [id: string]: IReportingObject };
    allMetrics: IMetric[];
    t: TFunction;
    selectedTab: TabListItemIds;
    currencyCode: number;
}

export const useDataAdapter = () => {
    const [filteredTenantsIds, setFilteredTenantsIds] = useState<number[]>([]);

    const {
        currentModuleID,
        src: { dataObj2ProjectCategory },
        cfg: { reportingObjectsById, tenant2ZoneByTenantId, tenant2FloorByTenantId, reportingObjectsByType },
    } = useGeneralSelector();
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const selectedTenantId = localCurrentOptions?.[PAYMENT_ANALYSIS_TENANT_SELECT]?.[0] as undefined | number;

    /** Фильтрация объектов */
    useEffect(() => {
        if (localCurrentOptions?.mainPeriod && localCurrentOptions?.mainDateRanges && selectedTenantId) {
            const selectedTenant = reportingObjectsById[selectedTenantId];
            const filteredArray = filterReportingObjects({
                initialArray: reportingObjectsByType['tenant'],
                dataObj2ProjectCategory: dataObj2ProjectCategory,
                tenant2FloorByTenantId: tenant2FloorByTenantId,
                tenant2ZoneByTenantId: tenant2ZoneByTenantId,
                mainDateRanges: localCurrentOptions.mainDateRanges,
                reportingObjectsById: reportingObjectsById,
                filters: localCurrentOptions?.['filters'] || [],
                mainPeriod: localCurrentOptions.mainPeriod,
            });

            if (selectedTenant) {
                filteredArray.unshift(selectedTenant);
            }

            setFilteredTenantsIds(filteredArray.map((item) => item.id));
        }
    }, [
        dataObj2ProjectCategory,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
        reportingObjectsById,
        localCurrentOptions,
        selectedTenantId,
        reportingObjectsByType,
    ]);

    const dataAdapter = (args: IArgs): TDataAdapterResponse => {
        const { response, reportingObjectsById, selectedTab, currencyCode } = args;

        const currencySymbol: string =
            currencyCode && currenciesMap[currencyCode] ? currenciesMap[currencyCode].symbol : '₽';

        const tenantsDataByTenantId: TTenantsData = {};
        const totalByColumnId: { [columnId: string]: TCellData } = {};

        const mainPeriodDefaultData = {
            value: null,
            percentageOfTheRowTotal: null,
            rowTotal: null,
        };

        const comparePeriodDefaultData = {
            value: null,
            percentageOfTheMain: null,
            rowTotal: null,
        };

        const columnsById: {
            [columnId: string]: IColumn;
        } = {
            tenant: { id: 'tenant', text: 'Tenant', rightHandOrderSwitcher: true },
            totalSales: {
                id: 'totalSales',
                text: 'Total sales',
                rightHandOrderSwitcher: true,
                leftHandOrderSwitcher: true,
                units: currencySymbol,
            },
        };

        /** Вспомогательная функция для подсчета суммы строки таблицы */
        const calculateRowTotal = (
            rowData: {
                [columnId: string]: TCellData;
            },
            valueType: 'mainValue' | 'compareValue',
        ): number | null => {
            const values = Object.values(rowData).map((cellData) => {
                return cellData[valueType]?.value || null;
            });
            return sum(values);
        };

        response.forEach((metricResponse) => {
            if (metricResponse.length) {
                metricResponse.forEach((metricResponseItem) => {
                    const { context } = metricResponseItem;
                    const tenant = context.data_objects[0];

                    if (!filteredTenantsIds.includes(tenant.id)) return;

                    const splittedAlias = context.alias?.split(DS);
                    const columnId = (context.alias || '').split(DS).slice(1).join(DS); // columnID without Period

                    let columnText = '';

                    switch (selectedTab) {
                        case TabListItemIds.PaymentType: {
                            columnText = PAYMENT_TYPE_TEXT_MAP[splittedAlias?.[splittedAlias?.length - 1] || ''];
                            break;
                        }

                        case TabListItemIds.OperationType: {
                            const opName = metricResponseItem.context.alias?.includes('return') ? 'Returns' : 'Sales';
                            const opVat = metricResponseItem.context.metric?.includes('novat')
                                ? '(Ex. VAT)'
                                : '(Inc. VAT)';
                            columnText = `${opName} ${opVat}`;
                            break;
                        }

                        default:
                            break;
                    }

                    columnsById[columnId] = {
                        units: currencySymbol,
                        rightHandOrderSwitcher: true,
                        leftHandOrderSwitcher: true,
                        text: columnText,
                        id: columnId,
                    };

                    const period = splittedAlias?.[0] as 'main' | 'compare' | undefined;

                    if (reportingObjectsById[tenant.id]) {
                        /** Если в объекте суммы по всем арендаторам нет колонки, то добавляем дефолтные значения*/
                        if (!totalByColumnId[columnId]) {
                            totalByColumnId[columnId] = {
                                mainValue: {
                                    ...mainPeriodDefaultData,
                                },
                                compareValue: {
                                    ...comparePeriodDefaultData,
                                },
                            };
                        }

                        /** Если в объекте еще не было данных по арендатору, то добавляем их */
                        if (!tenantsDataByTenantId[tenant.id]) {
                            tenantsDataByTenantId[tenant.id] = {
                                tenantData: reportingObjectsById[tenant.id],
                                metricsDataByColumnId: {},
                            };
                        }

                        /** Если у арендатора нет информации по текущей колонки, то добавляем ее */
                        if (!tenantsDataByTenantId[tenant.id].metricsDataByColumnId[columnId]) {
                            tenantsDataByTenantId[tenant.id].metricsDataByColumnId[columnId] = {
                                mainValue: {
                                    ...mainPeriodDefaultData,
                                },
                                compareValue: {
                                    ...comparePeriodDefaultData,
                                },
                            };
                        }

                        switch (period) {
                            case 'main':
                                tenantsDataByTenantId[tenant.id].metricsDataByColumnId[columnId].mainValue = {
                                    ...mainPeriodDefaultData,
                                    value: metricResponseItem.items[0].value,
                                };
                                totalByColumnId[columnId].mainValue = {
                                    ...mainPeriodDefaultData,
                                    value: sum([
                                        totalByColumnId[columnId].mainValue.value,
                                        metricResponseItem.items[0].value,
                                    ]),
                                };
                                break;
                            case 'compare':
                                tenantsDataByTenantId[tenant.id].metricsDataByColumnId[columnId].compareValue = {
                                    ...comparePeriodDefaultData,
                                    value: metricResponseItem.items[0].value,
                                };
                                totalByColumnId[columnId].compareValue = {
                                    ...comparePeriodDefaultData,
                                    value: sum([
                                        totalByColumnId[columnId].compareValue.value,
                                        metricResponseItem.items[0].value,
                                    ]),
                                };
                                break;

                            default:
                                break;
                        }
                    }
                });
            }
        });

        /**
         * Вспомогательная функция для получения процентных соотношения для mainPeriod и
         * comparePeriod
         */
        const calcDataByColumnId = (data: { [columnId: string]: TCellData }) => {
            const mainRowTotal = calculateRowTotal(data, 'mainValue');
            const compareRowTotal = calculateRowTotal(data, 'compareValue');

            const extendedDataByColumnId = Object.entries(data).reduce(
                (
                    acc: {
                        [columnId: string]: TCellData;
                    },
                    [columnId, metricData],
                ) => {
                    let percentageOfTheRowTotal: number | null = null;
                    let percentageOfTheMain: number | null = null;

                    if (isNumber(metricData.mainValue.value) && isNumber(mainRowTotal) && mainRowTotal > 0) {
                        percentageOfTheRowTotal = (metricData.mainValue.value * 100) / mainRowTotal;
                    }

                    if (
                        isNumber(metricData.mainValue.value) &&
                        isNumber(metricData.compareValue.value) &&
                        metricData.compareValue.value > 0
                    ) {
                        percentageOfTheMain = Number(
                            getDifferenceBetweenNumbers(metricData.mainValue.value, metricData.compareValue.value)
                                .percentDifference,
                        );
                    }
                    acc[columnId] = {
                        mainValue: {
                            ...metricData.mainValue,
                            percentageOfTheRowTotal,
                            rowTotal: mainRowTotal,
                        },
                        compareValue: {
                            ...metricData.compareValue,
                            rowTotal: compareRowTotal,
                            percentageOfTheMain,
                        },
                    };
                    return acc;
                },
                {
                    totalSales: {
                        mainValue: {
                            value: mainRowTotal,
                            percentageOfTheRowTotal: 100,
                            rowTotal: mainRowTotal,
                        },
                        compareValue: {
                            value: compareRowTotal,
                            rowTotal: compareRowTotal,
                            percentageOfTheMain: Number(
                                getDifferenceBetweenNumbers(mainRowTotal || undefined, compareRowTotal || undefined)
                                    .percentDifference,
                            ),
                        },
                    },
                },
            );

            return extendedDataByColumnId;
        };

        return {
            isDataExists: true,
            columns: Object.values(columnsById),
            totalByColumnId: calcDataByColumnId(totalByColumnId),
            averageByColumnId: calcDataByColumnId(
                Object.entries(totalByColumnId).reduce((acc: IAverageByColumnId, [columnId, columnData]) => {
                    acc[columnId] = {
                        ...columnData,
                        mainValue: {
                            ...columnData.mainValue,
                            value: columnData.mainValue.value
                                ? columnData.mainValue.value / (Object.keys(tenantsDataByTenantId).length || 1)
                                : null,
                        },
                        compareValue: {
                            ...columnData.compareValue,
                            value: columnData.compareValue.value
                                ? columnData.compareValue.value / (Object.keys(tenantsDataByTenantId).length || 1)
                                : null,
                        },
                    };
                    return acc;
                }, {}),
            ),
            tenantsDataById: Object.entries(tenantsDataByTenantId).reduce(
                (acc: TTenantsData, [tenantId, tenantData]) => {
                    const extendedDataByColumnId = calcDataByColumnId(tenantData.metricsDataByColumnId);

                    acc[tenantId] = { ...tenantData, metricsDataByColumnId: extendedDataByColumnId };

                    return acc;
                },
                {},
            ),
        };
    };

    return dataAdapter;
};
