import { useContext, useEffect, useState } from 'react';
import { SalesOfTenantsContext } from '../../../../context';
import { Tr } from '../../styles';
import { MetricValue, PercentageValue, Td } from './styles';
import { IReportingObject } from '../../../../../../../../../General.interfaces';
import { useSelector } from 'react-redux';
import { useWidgetCurrentOptions } from '../../../../../../../../../hooks/useWidgetCurrentOptions';
import filterReportingObjects from '../../../../../../../../../components/Filters/tools/filterReportingObjects';
import { valueFormatter } from '../../../../../../../../../tools/Strings/valueFormatter';
import sortTenants from './tools/sortTenants';
import Stack from '../../../../../../../../../components/Stack/Stack';
import { DS } from '../../../../../../../../../constants/constants';
import { TCellData } from '../../../../interfaces';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values } from '../../../../reducer';
import { TableSettingsIds } from '../../../../enums';
import { PAYMENT_ANALYSIS_TENANT_SELECT } from '../../../../../../../constants/constants';

/**
 * Компонент для отображения тела таблицы
 */
const Body = () => {
    const context = useContext(SalesOfTenantsContext);
    const { tableItemsToShow, tableOrderData, selectedTableSetting } = useSelector(
        Sales_PaymentAnalysis_SalesOfTenants_Widget_Reducer_Values,
    );
    const {
        currentModuleID,
        src: { dataObj2ProjectCategory },
        cfg: { reportingObjectsById, tenant2ZoneByTenantId, tenant2FloorByTenantId },
    } = useSelector(generalReducerValues);

    const [filteredTenants, setFilteredTenants] = useState<IReportingObject[]>([]);
    const [sortedTenants, setSortedTenants] = useState<IReportingObject[]>([]);

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const selectedTenantId = localCurrentOptions?.[PAYMENT_ANALYSIS_TENANT_SELECT]?.[0] as undefined | number;

    useEffect(() => {
        if (context?.data?.tenantsDataById && tableOrderData && selectedTenantId) {
            const sortedData = sortTenants({
                tenantsById: context.data.tenantsDataById,
                tenants: filteredTenants,
                selectedTenantId,
                tableOrderData,
                selectedTableSetting,
            });

            setSortedTenants(sortedData);
        } else {
            setSortedTenants(filteredTenants);
        }
    }, [context, filteredTenants, tableOrderData, selectedTenantId]);

    /** Фильтрация объектов */
    useEffect(() => {
        if (localCurrentOptions?.mainPeriod && localCurrentOptions?.mainDateRanges && selectedTenantId) {
            const selectedTenant = Object.values(context?.data?.tenantsDataById || {}).find(
                (element) => element.tenantData.id === selectedTenantId,
            );
            const filteredArray = filterReportingObjects({
                initialArray: Object.values(context?.data?.tenantsDataById || {})
                    .filter((element) => element.tenantData.id !== selectedTenantId)
                    .map((element) => element.tenantData),
                dataObj2ProjectCategory: dataObj2ProjectCategory,
                tenant2FloorByTenantId: tenant2FloorByTenantId,
                tenant2ZoneByTenantId: tenant2ZoneByTenantId,
                mainDateRanges: localCurrentOptions.mainDateRanges,
                reportingObjectsById: reportingObjectsById,
                filters: localCurrentOptions?.['filters'] || [],
                mainPeriod: localCurrentOptions.mainPeriod,
            });
            if (selectedTenant) {
                filteredArray.unshift(selectedTenant.tenantData);
            }
            setFilteredTenants(filteredArray);
        }
    }, [
        localCurrentOptions?.mainDateRanges,
        localCurrentOptions?.mainPeriod,
        context?.data?.tenantsDataById,
        dataObj2ProjectCategory,
        tenant2FloorByTenantId,
        tenant2ZoneByTenantId,
        reportingObjectsById,
        localCurrentOptions,
        selectedTenantId,
    ]);

    return (
        <tbody>
            {sortedTenants
                ?.slice(0, tableItemsToShow === 'all' ? filteredTenants?.length : tableItemsToShow)
                .map((tenant) => {
                    const tenantData = context?.data?.tenantsDataById?.[tenant.id];
                    const bgColor = selectedTenantId === tenant.id ? '#f2fddb' : 'inherit';
                    return (
                        <Tr key={tenantData?.tenantData.id}>
                            <Td bgColor={bgColor}>{tenantData?.tenantData.name}</Td>
                            {/* <Td bgColor={bgColor}>{tenantData?.tenantData.name}</Td> */}
                            {context?.data?.columns
                                ?.filter((column) => column.id !== 'tenant')
                                .map((column) => {
                                    const metricData = tenantData?.metricsDataByColumnId[column.id] as
                                        | TCellData
                                        | undefined;

                                    return (
                                        <Td key={`${tenantData?.tenantData.id}${DS}${column.id}`} bgColor={bgColor}>
                                            <Stack justifyContent="space-between" alignItems="center" gap={5}>
                                                <MetricValue>
                                                    {valueFormatter({
                                                        value:
                                                            selectedTableSetting.id ===
                                                            TableSettingsIds.ShowAbsoluteValues
                                                                ? metricData?.mainValue.value
                                                                : metricData?.mainValue.percentageOfTheRowTotal,
                                                        units:
                                                            selectedTableSetting.id ===
                                                            TableSettingsIds.ShowAbsoluteValues
                                                                ? undefined
                                                                : '%',
                                                    })}
                                                </MetricValue>
                                                <PercentageValue value={metricData?.compareValue.percentageOfTheMain}>
                                                    {valueFormatter({
                                                        value: metricData?.compareValue.percentageOfTheMain,
                                                        units: '%',
                                                    })}
                                                </PercentageValue>
                                            </Stack>
                                        </Td>
                                    );
                                })}
                        </Tr>
                    );
                })}
        </tbody>
    );
};

export default Body;
