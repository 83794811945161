import { emptyArray } from 'src/tools';

import { TFunction, TTranslationNS } from 'src/hooks/useTranslation';

import { PointsOfSalesResponse, PointOfSale, ReportingObjectsById, TenantPosInfo } from '../interfaces';

import { posDataByTenantId, aggregateField } from './helpers';

export function tenantsAndPosAdapter({
    response,
    reportingObjectsById,
    t,
}: {
    response: PointsOfSalesResponse;
    reportingObjectsById: ReportingObjectsById;
    t: TFunction<TTranslationNS>;
}) {
    const { results = emptyArray as PointOfSale[] } = response?.data;

    const possByTenant = posDataByTenantId(results, reportingObjectsById, t);

    const tenantData = Object.keys(possByTenant).reduce((acc, tenantId) => {
        const tenantPoss = possByTenant[tenantId];

        const uniqueInns = [...new Set(aggregateField(tenantPoss, 'inn'))];

        const tenantName = reportingObjectsById[tenantId].object_name;

        const [pos] = tenantPoss;

        const uniqRegIds = Array.from(
            new Set(
                (tenantPoss as { registrationId: number }[])
                    .filter((item) => item?.registrationId)
                    .map((pos) => pos.registrationId),
            ),
        );

        const count = uniqRegIds.length; // count todo retrieve from data
        // pos.isPosOnline,
        const activeCount = aggregateField(tenantPoss, 'posName').filter(Boolean).length;

        const tenantData = {
            id: tenantId,
            tenantName,
            orgName: pos.orgName,
            inns: uniqueInns.filter((inn) => inn?.length > 0).join(', '),
            posType: [...new Set(aggregateField(tenantPoss, 'posType'))].join(', '),
            // posName: aggregateField(tenantPoss, 'posName').join(', '),
            // posId: posData.id, // name todo retrieve from data may be dataseries for chart
            // posCount: count === activeCount ? count.toString() : `${activeCount}/${count}`,
            posCount: count,
        };

        return [...acc, tenantData];
    }, []);

    return tenantData;
}
