import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Modals from '../../../../../components/Modals/Modals';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import { storeSelectedEventId as storeSelectedEventIdForEventsModule } from '../../../Events/reducer';
import Calendar from './components/Calendar/Calendar';
import EventModalBody from './components/EventModalBody/EventModalBody';
import MultipleEventsModalBody from './components/MultipleEventsModalBody/MultipleEventsModalBody';
import Sidebar from './components/Sidebar/Sidebar';
import { EventsMapWidgetContext, IEventsMapWidgetContext } from './context';
import { IEvent, IEventTypeToUpdate, IExtendedEvent } from './interfaces';

import {
    Events_EventsMap_Widget_Reducer_Values,
    reloadWidgets,
    storeEventIdToDelete,
    storeEventsToUpdate,
    storeEventTypeToUpdate,
    storeNewEvents,
    storeSelectedEventId,
    storeSelectedEventIdToEdit,
} from './reducer';
import { ButtonsWrapper, MapWrapper, WidgetTitleWrapper, Wrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import { generalReducerValues } from '../../../../../General.reducer';
import Button from '../../../../../components/Button/Button';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';

/**
 * Компонент для отображения виджета карты событий (календаря)
 */
const EventsMapWidget = () => {
    const { extendedEventsDataById, selectedEventId, selectedEventIdToEdit, eventTypes } = useSelector(
        Events_EventsMap_Widget_Reducer_Values,
    );
    const [multipleEventsModalStatus, setMultipleEventsModalStatus] = useState({ show: false });
    const [eventModalStatus, setEventModalStatus] = useState({ show: false });
    const { isRtlLanguage, user } = useSelector(generalReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const ref = useRef(null);

    useEffect(() => {
        if (selectedEventId) {
            dispatch(storeSelectedEventIdForEventsModule(selectedEventId));
            dispatch(storeSelectedEventId(null));
            navigate('/events/events');
        }
    }, [selectedEventId]);

    useEffect(() => {
        if (selectedEventIdToEdit) {
            openEventModal();
        }
    }, [selectedEventIdToEdit]);

    const closeEventModal = () => {
        setEventModalStatus({ show: false });
        dispatch(storeSelectedEventIdToEdit(null));
    };

    const openEventModal = () => {
        setEventModalStatus({ show: true });
    };

    const closeMultipleEventsModal = () => {
        setMultipleEventsModalStatus({ show: false });
    };

    const storeNewEventsTrans = (events: IEvent[]) => {
        dispatch(storeNewEvents(events));
    };

    const storeEventTypeToUpdateTrans = (event: IEventTypeToUpdate | null) => {
        dispatch(storeEventTypeToUpdate(event));
    };

    const storeSelectedEventIdTrans = (id: number) => {
        dispatch(storeSelectedEventId(id));
    };

    const storeEventIdToDeleteTrans = (id: number) => {
        dispatch(storeEventIdToDelete(id));
    };

    const storeEventsToUpdateTrans = (events: IExtendedEvent[]) => {
        dispatch(storeEventsToUpdate(events));
    };

    const storeSelectedEventIdToEditTrans = (id: number) => {
        dispatch(storeSelectedEventIdToEdit(id));
    };

    const context: IEventsMapWidgetContext = {
        extendedEventsDataById,
        isRtlLanguage,
        storeSelectedEventIdToEditTrans,
        storeEventTypeToUpdateTrans,
        storeSelectedEventIdTrans,
        storeEventIdToDeleteTrans,
        storeEventsToUpdateTrans,
        storeNewEventsTrans,
        closeEventModal,
    };

    return (
        <EventsMapWidgetContext.Provider value={context}>
            <WidgetWrapper
                styles={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '0px',
                }}
                ref={ref}
            >
                <Modals
                    title={
                        selectedEventIdToEdit
                            ? t(extendedEventsDataById[selectedEventIdToEdit].name)
                            : t('Add new event')
                    }
                    closeModal={closeEventModal}
                    modalStatus={eventModalStatus}
                    topColor="fff"
                >
                    <EventModalBody
                        event={selectedEventIdToEdit ? extendedEventsDataById[selectedEventIdToEdit] : undefined}
                        closeModal={closeEventModal}
                    />
                </Modals>
                <Modals
                    title={t('Add multiple events')}
                    closeModal={closeMultipleEventsModal}
                    modalStatus={multipleEventsModalStatus}
                    topColor="fff"
                >
                    <MultipleEventsModalBody />
                </Modals>
                <WidgetTitleWrapper isRtlLanguage={isRtlLanguage}>
                    <WidgetTitle>
                        {t('Events map')}
                        <WidgetAdditionalControls
                            pdfDownloadData={{ targetRef: ref }}
                            reloadHandler={reloadWidgets}
                            widgetName={'Events map'}
                        />
                    </WidgetTitle>
                    {user?.permissions.includes('app/client | modules/configuration/events | feature/edit') && (
                        <ButtonsWrapper>
                            {/* <Button disabled onClick={openMultipleEventsModal}>
                            {t('Add multiple events')}
                        </Button> */}

                            <Tooltip
                                showTooltip={!eventTypes.length}
                                content={t('You should add a new event type first!')}
                            >
                                <Button appearance="primary" onClick={openEventModal}>
                                    {t('Add new event')}
                                </Button>
                            </Tooltip>
                        </ButtonsWrapper>
                    )}
                </WidgetTitleWrapper>
                <Wrapper>
                    <Sidebar />
                    <MapWrapper>
                        <Calendar />
                    </MapWrapper>
                </Wrapper>
            </WidgetWrapper>
        </EventsMapWidgetContext.Provider>
    );
};

export default EventsMapWidget;
