import { useQuery } from '@tanstack/react-query';

import { SALES_POS_ANALYSIS_QUERY_KEY } from 'src/constants';
import { getRequest } from 'src/tools/API/appBackendAPI';
import usePeriods from 'src/hooks/common/usePeriods';
import useTranslation from 'src/hooks/useTranslation/useTranslation';
import { useWidgetCurrentOptions } from 'src/hooks/useWidgetCurrentOptions';
import { emptyArray } from 'src/tools';
import { useGeneralSelector } from 'src/hooks';

import { DataAdapter } from './interfaces';

// TODO extends it from pattern src/Chapters/Performance/Dashboard/widgets/AnalysisByArea/api/useFetchData.ts
export const useFetchPosEntities = <Args, Result>(dataAdapter: DataAdapter<Args, Result>) => {
    const {
        cfg: { reportingObjectsById },
        currentModuleID,
        token,
    } = useGeneralSelector();

    const { t } = useTranslation(['fsfReports']);

    const { mainPeriod } = usePeriods(currentModuleID);
    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);

    const period = mainPeriod?.period;

    const tenantIds = localCurrentOptions?.selectedReportingObjectsIds || emptyArray;
    const hasTenantsParams = tenantIds?.length !== 0;

    const [pl] = localCurrentOptions?.pl || [];
    const isAllTime = localCurrentOptions?.allTime;

    const params = [
        ...(pl?.id && !hasTenantsParams ? [`project_location_id=${pl?.id}`] : []),
        ...(hasTenantsParams ? [`tenant_ids=${tenantIds!.join(',')}`] : []),
        ...(isAllTime ? ['without_data=false'] : []),
        ...(!isAllTime && period?.dateFrom ? [`date_from=${period.dateFrom}`] : []),
        ...(!isAllTime && period?.dateTo ? [`date_to=${period.dateTo}`] : []),
    ];

    const paramsUrl = params?.length > 0 ? `?${params.join('&')}` : '';

    const requestUrl = `https://fsf-api.sales-flow.ru/api/points-of-sale/${paramsUrl}`;

    const queryKey = [SALES_POS_ANALYSIS_QUERY_KEY, currentModuleID, pl?.id, tenantIds, mainPeriod, isAllTime];
    const queryFn = () => getRequest(requestUrl, token);

    const enabled = Boolean(queryKey) && Boolean(queryFn) && Boolean(token) && Boolean(mainPeriod) && Boolean(pl?.id);

    return useQuery({
        queryKey,
        queryFn,
        enabled,
        select: (response) => dataAdapter({ response, reportingObjectsById, t } as Args),
    });
};