import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../General.reducer';
import MainPeriodSelect from '../../../components/Selects/MainPeriodSelect/MainPeriodSelect';
import ModuleWrapper from '../../../components/Wrappers/ModuleWrapper/ModuleWrapper';
import WrapperContainer from '../../../components/Wrappers/WrapperContainer/WrapperContainer';
import Preset from '../../../components/Preset/Preset';
import SelectsWrapper from '../../../components/Wrappers/SelectsWrapper/SelectsWrapper';
import PresetsWrapper from '../../../components/Wrappers/PresetsWrapper/PresetsWrapper';
import PaymentStructure from './widgets/PaymentStructure/PaymentStructure';
import StructureDynamics from './widgets/StructureDynamics/StructureDynamics';
import ComparePeriodSelect from '../../../components/Selects/ComparePeriodSelect/ComparePeriodSelect';
import {
    PAYMENT_ANALYSIS_TENANT_SELECT,
    VAT_SELECT,
    VAT_OPTIONS,
    CORRECTION_OPTIONS,
} from '../constants/constants';
import { useTranslation } from 'react-i18next';
import UniversalSelect from '../../../components/Selects/UniversalSelect/UniversalSelect';
import { ISettings } from '../../../components/UserSettings/interfaces';
import SalesOfTenants from './widgets/SalesOfTenants/SalesOfTenants';
import ModuleOptionsPanel from '../../../components/ModuleOptionsPanel/ModuleOptionsPanel';
import { OptionsWrapper } from '../../../components/Wrappers/OptionsWrapper/styles';
import { useWidgetCurrentOptions } from '../../../hooks/useWidgetCurrentOptions';
import { useAppDispatch } from '../../../hooks/useSettings';
import { changeOptions } from '../../../components/UserSettings/reducer';
import ObjectsSelect from '../../../components/Selects/ObjectsSelect/ObjectsSelect';

/**
 * Компонент для отображения модуля Анализ платежей
 */
const SalesAnalysis: React.FC = () => {
    const {
        currentModuleID,
        cfg: { reportingObjectsByType },
    } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();

    const localCurrentOptions = useWidgetCurrentOptions(currentModuleID);
    const { t } = useTranslation();

    const tenantSelectOptions = useMemo(() => {
        return reportingObjectsByType['tenant']?.map((item) => ({ id: item.id, text: item.name })) || [];
    }, [reportingObjectsByType]);

    return (
        <ModuleWrapper>
            <ModuleOptionsPanel>
                <OptionsWrapper>
                    <SelectsWrapper>
                        <MainPeriodSelect
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <ComparePeriodSelect
                            mode="single"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        {/* <UniversalSelect
                                name={paymentAnalysisTenantSelect}
                                options={tenantSelectOptions}
                                text={t('Select tenant')}
                                labelText={t('Tenant')}
                                dropdownWidth="sm"
                                mode="single"
                                showFilter
                                localCurrentOptions={localCurrentOptions}
                                changeOptions={(args) => dispatch(changeOptions(args))}
                            /> */}
                        <ObjectsSelect
                            name={PAYMENT_ANALYSIS_TENANT_SELECT}
                            mode="single"
                            validOptions={['Tenants']}
                            text={t('Select tenant')}
                            label={t('Tenant')}
                            dataObjectFilters={['data_objects_tenant']}
                            showSelectedOptionsCount={false}
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <UniversalSelect
                            name={'useVAT'}
                            options={VAT_OPTIONS}
                            text={t('Use VAT')}
                            labelText={t('Use VAT')}
                            dropdownWidth="sm"
                            mode="single"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                        <UniversalSelect
                            name={'correctionReceipts'}
                            options={CORRECTION_OPTIONS}
                            defaultSelected={[CORRECTION_OPTIONS[0]]}
                            text={t('Correction receipts')}
                            labelText={t('Correction receipts')}
                            dropdownWidth="auto"
                            mode="single"
                            localCurrentOptions={localCurrentOptions}
                            changeOptions={(args) => dispatch(changeOptions(args))}
                        />
                    </SelectsWrapper>
                    <PresetsWrapper>
                        <Preset
                            currentOptionsKeys={
                                [
                                    PAYMENT_ANALYSIS_TENANT_SELECT,
                                    VAT_SELECT,
                                    'comparePeriods',
                                    'mainPeriod',
                                ] as (keyof ISettings)[]
                            }
                        />
                    </PresetsWrapper>
                </OptionsWrapper>
            </ModuleOptionsPanel>
            <WrapperContainer>
                <PaymentStructure />
                <StructureDynamics />
                <SalesOfTenants />
            </WrapperContainer>
        </ModuleWrapper>
    );
};

export default SalesAnalysis;
