import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AxisLabelsFormatterContextObject } from 'highcharts';

import WidgetTitleWrapper from 'src/components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';

import Dynamics from '../../../../../components/Charts/Dynamics/Dynamics';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import { generalReducerValues } from '../../../../../General.reducer';
import { useWidgetCurrentOptions } from '../../../../../hooks/useWidgetCurrentOptions';
import { withLoading } from '../../../../../tools/API/withLoading';
import { ResponseStatus } from '../../../../../tools/API/constants';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import { getObjectName } from '../../../../../hooks/useObjectTranslation';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';

import {
    ChartContainer,
    HeaderWrapper,
    ReportingObjectType,
    VisitorsAtTheMomentWrapper,
    VisitorsCount,
    VisitorsInsideCurrentTime,
    Wrapper,
} from './styles';
import usePrepareData from './hooks/usePrepareData';
import { IShowSharedTooltips } from './interfaces';
import { Performance_VisitorsInside_Widget_Reducer_Values, reloadWidget } from './reducer';

/**
 * Виджет для отображения посетителей внутри
 */
const VisitorsInside = () => {
    const { extendedReportingObjectsById, reportingObjectsRawMetricsDataById, moduleName } = useSelector(
        Performance_VisitorsInside_Widget_Reducer_Values,
    );
    const localCurrentOptions = useWidgetCurrentOptions(moduleName);
    const [showSharedTooltips, setShowSharedTooltips] = useState<IShowSharedTooltips>({
        containerId: null,
        show: false,
        index: null,
    });
    const [chartsIntsance, setChartsInstane] = useState<Highcharts.Chart[]>([]);
    const { lang } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const itemsRef = useRef<Array<HTMLDivElement | null>>([]);
    usePrepareData();

    /**
     * Одновременное отображение нескольких тултипов
     */
    useEffect(() => {
        if (showSharedTooltips.show) {
            chartsIntsance.forEach((instance) => {
                if (showSharedTooltips.containerId !== instance?.container?.id) {
                    if (instance.series?.length) {
                        const tooltipsToUpdate = instance.series
                            .map((element) => element.data[showSharedTooltips.index || 0])
                            ?.filter((element) => element?.y !== null && element?.y !== undefined);

                        tooltipsToUpdate.length && instance.tooltip.refresh(tooltipsToUpdate);
                    }
                }
            });
        } else {
            chartsIntsance.length && chartsIntsance.forEach((instance) => instance.tooltip?.hide());
        }
    }, [showSharedTooltips]);

    /**
     * Сохранение экземпляров класса графиков для работы с тултипами
     */
    const storeChartInstance = (chart: Highcharts.Chart) => {
        const chartInstance = chartsIntsance?.find((element) => element?.container?.id === chart.container.id);
        if (!chartInstance) {
            setChartsInstane((prevState) => {
                prevState.push(chart);
                return prevState;
            });
        }
    };

    /**
     * Форматирование лейблов на оси X
     */
    const xAxisLabelsFormating = function (this: AxisLabelsFormatterContextObject) {
        return DateTime.fromMillis(this.value as number)
            .toUTC()
            .toFormat('HH:mm');
    };

    const visitorsInsideText = t('visitors inside in');

    const charts = useMemo(() => {
        return Object.keys(extendedReportingObjectsById).map((key, i) => {
            const { objectInfo, chartOptionsGeneratorSettings, visitorsAtTheMoment } =
                extendedReportingObjectsById[key];

            const WithLoadingTable = withLoading(ChartContainer, {
                data: reportingObjectsRawMetricsDataById[key] || {
                    status: ResponseStatus.Loading,
                    message: t('Loading...'),
                },
                height: 400,
            });

            return (
                <WidgetWrapper key={objectInfo?.id} ref={(el: any) => (itemsRef.current[i] = el)}>
                    <WidgetTitleWrapper>
                        <WidgetTitle>
                            <ReportingObjectType>
                                {getObjectName({ reportingObject: objectInfo, t, showType: true })}
                            </ReportingObjectType>
                            <WidgetAdditionalControls
                                pdfDownloadData={{ targetRef: { current: itemsRef.current[i] } }}
                                widgetName={'Visitors inside'}
                                reloadHandler={i === 0 ? reloadWidget : undefined}
                            />
                        </WidgetTitle>
                    </WidgetTitleWrapper>
                    <HeaderWrapper>
                        {localCurrentOptions?.mainPeriod?.id === 'today' ? (
                            chartOptionsGeneratorSettings ? (
                                <VisitorsAtTheMomentWrapper>
                                    <VisitorsCount>{visitorsAtTheMoment}</VisitorsCount>
                                    <VisitorsInsideCurrentTime>
                                        {`${visitorsInsideText} ${DateTime.now()
                                            .setZone(chartOptionsGeneratorSettings.timezone)
                                            .toFormat('HH:mm')}`}
                                    </VisitorsInsideCurrentTime>
                                </VisitorsAtTheMomentWrapper>
                            ) : null
                        ) : null}
                    </HeaderWrapper>
                    <WithLoadingTable>
                        {chartOptionsGeneratorSettings && (
                            <Dynamics
                                toggleShowSharedTooltips={setShowSharedTooltips}
                                xAxisLabelsFormating={xAxisLabelsFormating}
                                storeChartInstance={storeChartInstance}
                                {...chartOptionsGeneratorSettings}
                                useUTC={true}
                            />
                        )}
                    </WithLoadingTable>
                </WidgetWrapper>
            );
        });
    }, [extendedReportingObjectsById, localCurrentOptions?.mainPeriod?.id, lang, visitorsInsideText, t]);

    return <Wrapper>{charts}</Wrapper>;
};

export default VisitorsInside;
