import { FC, useEffect, useState } from 'react';
import { TChartSettingsKeys, TChartSettingsValues } from '../../../../ChartSettings/interfaces';
import {
    chartSettingsReducerValues,
    storeChartSettingsById,
    updateChartSettingsById,
} from '../../../../ChartSettings/reducer';
import { useDispatch, useSelector } from 'react-redux';

import ContextualMenu from '../../../../ContextualMenu/ContextualMenu';
import ContextualMenuBody from './components/ContextualMenuBody/ContextualMenuBody';
import { IProps } from './interfaces';
import { Wrapper } from './styles';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../Tooltip/Tooltip';
import IconButton from '../../../../IconButton/IconButton';
import { Settings24Regular } from '@fluentui/react-icons';
import { generalReducerValues } from '../../../../../General.reducer';
import { useSendSimpleAnalytics } from '../../../../../hooks/useAnalytics';

/**
 * Отображение иконки с настройками диаграммы
 * @param onSeriesSelect Функция выбора сер ии, на основе которой построить дополнительную серию
 * @param series все серии (включая новые)
 */
const DynamicsOptions: FC<IProps> = ({ series, chartId, testId = '' }) => {
    console.log('DynamicsOptions', series, chartId, testId);

    const sendAnalytics = useSendSimpleAnalytics();
    const [weekendsHighlightPossibility, setWeekendsHighlightPossibility] = useState(false);
    const { sendYaAnalytics, currentModuleID } = useSelector(generalReducerValues);
    const [isContextualMenuOpen, setContextualMenuOpen] = useState(false);
    const { chartSettingsById } = useSelector(chartSettingsReducerValues);
    const DAY_IN_MILLIS = 86400000;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            updateChartSettingsById({
                value: weekendsHighlightPossibility,
                key: 'highlightWeekends',
                chartId,
            }),
        );
    }, [weekendsHighlightPossibility]);

    useEffect(() => {
        if (series.length) {
            let flag = false;
            if (series[0].data.length > 1) {
                if (series[0].data[1].x - series[0].data[0].x === DAY_IN_MILLIS) {
                    flag = true;
                }
            }
            setWeekendsHighlightPossibility(flag);
        }
    }, [series]);

    useEffect(() => {
        if (!chartSettingsById[chartId]) {
            dispatch(
                storeChartSettingsById({
                    [chartId]: {
                        selectedSeriesIdsForAverage: [],
                        selectedSeriesIdsForTrend: [],
                        highlightWeekends: false,
                    },
                }),
            );
        }
    }, [chartId, chartSettingsById]);

    const updateChartSettingsByIdTrans = (args: {
        value: TChartSettingsValues;
        key: TChartSettingsKeys;
        chartId: string;
    }) => {
        sendAnalytics('change_chart_diagram_settings');

        dispatch(updateChartSettingsById(args));
    };

    const toggleContextualMenuOpen = () => {
        setContextualMenuOpen((prevState) => !prevState);
    };

    const closeContextualMenu = () => {
        setContextualMenuOpen(false);
    };

    return (
        <ContextualMenu
            contextualMenuBody={
                <ContextualMenuBody
                    weekendsHighlightPossibility={weekendsHighlightPossibility}
                    updateChartSettingsByIdTrans={updateChartSettingsByIdTrans}
                    chartSettings={chartSettingsById[chartId]}
                    chartId={chartId}
                    series={series}
                />
            }
            isContextualMenuOpen={isContextualMenuOpen}
            closeContextualMenu={closeContextualMenu}
            gapSpace={10}
        >
            <Wrapper>
                <Tooltip content={t('Diagram settings')}>
                    <IconButton
                        testId={testId}
                        onClick={toggleContextualMenuOpen}
                        size={30}
                        icon={<Settings24Regular />}
                    />
                </Tooltip>
            </Wrapper>
        </ContextualMenu>
    );
};

export default DynamicsOptions;
