import { TFunction } from 'i18next';
import { IMetric, TMetricResponse } from '../../../../../../General.interfaces';
import { isNumber, isUndefined, sum } from 'lodash';
import { IExtendedChartPoint } from '../../../../../../components/Charts/interfaces';
import Highcharts from 'highcharts';
import { DS } from '../../../../../../constants/constants';
import { theme } from 'src/theme';
import { IRatingItem } from '../interfaces';
import { valueFormatter } from '../../../../../../tools/Strings/valueFormatter';
import { PAYMENT_TYPE_TEXT_MAP, PAYMENT_TYPES } from '../../../../constants/constants';

import { currenciesMap } from '../../../../../../constants/currencies';
import { stringHsl2Hex } from 'src/tools/hsl2hex';

interface IArgs {
    currencyCode: number;
    /** Ответ от сервера. Сюда приходит ответ от запроса метрик */
    response: TMetricResponse[];
    /** Все метрики */
    allMetrics: IMetric[];
    /** vat or novat */
    vatMode: string;
    /** Функция перевода */
    t: TFunction;
}

const dataAdapter = (
    args: IArgs,
): {
    isDataExists: boolean;
    chartOptions: Highcharts.Options;
    ratingItems: { salesRating: IRatingItem[]; returnsRating: IRatingItem[] };
} => {
    const { response, currencyCode, t } = args;

    const currencySymbol: string =
        currencyCode && currenciesMap[currencyCode] ? currenciesMap[currencyCode].symbol : '₽';

    const metricsData: IExtendedChartPoint[] = [];
    let typesData: IExtendedChartPoint[] = [];

    const sales = response.filter((item) => !item[0].context.alias?.includes('return')).map((item) => item[0]);
    const returns = response.filter((item) => item[0].context.alias?.includes('return')).map((item) => item[0]);

    const [salesRating, returnsRating] = [sales, returns].map((operation) => {
        const maxValue = Math.max(...operation.map((item) => item.items[0].value || 0));
        const operationTotal = sum(operation.map((item) => item.items[0].value || 0));
        const opName = operation[0].context.alias?.includes('return') ? 'Returns' : 'Sales';
        const opVat = operation[0].context.metric?.includes('novat') ? '(Ex. VAT)' : '(Inc. VAT)';
        const operationName = `${opName} ${opVat}`;
        return operation
            .map((item) => {
                const value = item.items?.[0]?.value as number | null;
                const type = item.context.alias?.split(DS)?.[1] || '';
                return {
                    alias: item.context.alias || '',
                    name: `${t(PAYMENT_TYPE_TEXT_MAP[type] || '')}`,
                    operationName: `${t(operationName)}`,
                    mainValue: {
                        value: valueFormatter({
                            value,
                            precision: 2,
                            units: currencySymbol,
                        }),
                        operationTotal,
                        percentOfOperationTotal: value ? (value * 100) / operationTotal : 0,
                        percentOfMax:
                            isNumber(maxValue) && maxValue > 0 && isNumber(value)
                                ? Math.round(100 * value) / maxValue
                                : 0,
                    },
                };
            })
            .sort((a, b) => b.mainValue.percentOfMax - a.mainValue.percentOfMax);
    });

    [sales, returns].forEach((operation, index) => {
        const operationTotal = sum(operation.map((item) => item.items[0].value || 0));
        const opName = operation[0].context.alias?.includes('return') ? 'Returns' : 'Sales';
        const opVat = operation[0].context.metric?.includes('novat') ? '(Ex. VAT)' : '(Inc. VAT)';
        const operationName = `${opName} ${opVat}`;

        const color = stringHsl2Hex(theme.seriesColors[index % theme.seriesColors.length]);

        operation.forEach((metricResponse) => {
            const value = metricResponse.items?.[0]?.value as number | null;
            const type = metricResponse.context.alias?.split(DS)?.[1] || '';

            /** Данные для серии по типам продаж */
            typesData.push({
                color,
                name: String(t(PAYMENT_TYPE_TEXT_MAP[type])),
                subTitle: currencySymbol,
                precision: 2,
                title: t('Type'),
                id: `${opName}${DS}${type}`,
                y: value,
            });
        });

        metricsData.push({
            color,
            name: t(operationName),
            subTitle: currencySymbol,
            precision: 2,
            title: t('Metric'),
            id: opName,
            y: operationTotal,
        });
    });

    /** Изменение цвета метрики типа относительно основной метрики */
    typesData = typesData.map((item) => {
        let itemColor = item.color;
        const metricId = item.id?.split(DS)?.[0];
        const currentMetricData = metricsData.find((item) => item.id === metricId);

        if (
            !isUndefined(currentMetricData) &&
            isNumber(currentMetricData?.y) &&
            isNumber(item.y) &&
            currentMetricData?.y > 0 &&
            item.color
        ) {
            const brighten = 0.3 - item.y / currentMetricData?.y / PAYMENT_TYPES.length;
            itemColor = Highcharts.color(item.color).brighten(brighten).get();
        }

        return {
            ...item,
            color: itemColor,
            dataLabels: {
                color: currentMetricData?.color || itemColor,
            },
        };
    });

    const isDataExists = [...metricsData, ...typesData]?.some((point) => isNumber(point.y) && point.y !== 0);

    return {
        isDataExists,
        ratingItems: { salesRating, returnsRating },
        chartOptions: {
            title: {
                text: '',
            },
            lang: {
                noData: t('No data'),
            },
            chart: {
                type: 'pie',
            },
            plotOptions: {
                pie: {
                    startAngle: 270,
                },
            },

            series: [
                {
                    name: 'Metrics data',
                    data: metricsData,
                    size: '60%',
                    dataLabels: {
                        color: '#ffffff',
                        distance: '-50%',
                    },
                    type: 'pie',
                },
                {
                    name: 'Types data',
                    data: typesData,
                    size: '100%',
                    innerSize: '60%',

                    dataLabels: {
                        style: {
                            fontWeight: 'normal',
                            textOutline: 'none',
                        },
                        filter: {
                            property: 'y',
                            operator: '>',
                            value: 0,
                        },
                    },
                    type: 'pie',
                },
            ],
        },
    };
};

export default dataAdapter;
