import { IDataAdapterResponseItem } from '../../../interfaces';
import { Name, Rating, RatingWrapper, Td } from '../../../styles';
import Stack from '../../../../../Stack/Stack';

export const CellRating = (props: { item: IDataAdapterResponseItem }) => {
    return (
        <Td>
            <Stack direction="column" gap={3} justifyContent="flex-end">
                <RatingWrapper>
                    <Name>{props.item.name}</Name>
                    <Rating width={props.item.mainValue.percentOfMax} />
                </RatingWrapper>
            </Stack>
        </Td>
    );
};
