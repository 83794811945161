import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Popover, PopoverProps, PopoverSurface, PopoverTrigger } from '@fluentui/react-components';
import cn from 'classnames';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Position } from '@fluentui/react-positioning';

import { theme } from 'src/theme';

import * as constants from '../../../constants/constants';
import sortOptions from './tools/sortOptions';
import useStyles from '../../stylesHooks/useStyles';
import Tooltip from '../../Tooltip/Tooltip';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import Checkbox from '../../Checkbox/Checkbox';
import Collapse from '../../Collapse/Collapse';
import Search from '../../Search/Search';
import Stack from '../../Stack/Stack';
import { generateSelector } from '../../../tools/generateSelector';

import {
    IObject,
    ISelectedOption,
    ISelectProps,
    ISortingConfig,
    TNestedOptions,
    TSelectedOptions,
    TSortingConfigSection,
} from './interfaces';
import { isNested, isSimple } from './tools/getOptionsType';
import {
    IconWrapper,
    SelectWrapper,
    StDropdown,
    SearchWrapper,
    CheckBoxWrapper,
    List,
    ButtonWrapper,
    OptionWrapper,
    TextWrapper,
    SelectButton,
    FilterWrapper,
    SortIcon,
    AdditionalControlsWrapper,
    SelectTooltipItem,
    Footer,
    CollapseWrapper,
} from './styles/styles';
import getSortingConfig from './tools/getSortingConfig';
import SelectAllClearAll from './components/SelectAllClearAll/SelectAllClearAll';
import { getPlainFilteredOptions } from './tools/getPlainFilteredOptions';
import { getFilteredOptions } from './tools/getFilteredOptions';

import './styles/styles.scss';

/**
 * Компонент для отображения селекта
 */
const Select: React.FC<ISelectProps> = ({
    testId,
    additionalFilterControl,
    additionalControl,
    showSorting,
    maxWidth,
    iconType,
    outsideCloseDropdown = { close: false },
    showSelectedOptionsCount = true,
    canSelectEntireSection = false,
    // dropdownAlign = 'left',
    outsideSelected = null,
    dropdownWidth = 'md',
    buttonText = 'Save',
    outsideText = null,
    fullWidth = false,
    showClearAll = false,
    showFilter = true,
    disabled = false,
    text = 'Selected',
    maxSelected = 0,
    minSelected = 0,
    labelText = '',
    mode = 'multi',
    options = [],
    onAdditionalFilterControlClick,
    handleSelect,
    handleCheck,
    styles,
    ...props
}) => {
    const LIST_MAX_HEIGHT = 400;
    const LIST_MIN_HEIGHT = 100;

    const [tempSelectedOptions, setTempSelectedOptions] = useState<TSelectedOptions>([]);
    const [selectedOptions, setSelectedOptions] = useState<TSelectedOptions>([]);

    const [sortingConfig, setSortingConfig] = useState<ISortingConfig>({});
    const [sortedOptions, setSortedOptions] = useState(options);

    const [showDropdown, setShowDropdown] = useState(false);
    const [filter, setFilter] = useState<string>('');

    const [selectButtonClickPosition, setSelectButtonClickPosition] = useState(0);
    const [listMaxHeight, setListMaxHeight] = useState(LIST_MAX_HEIGHT);
    const [position, setPosition] = useState<Position>('below');
    const [offset, setOffset] = useState<number>(5);

    const mergedStyles = useStyles({ props, constants });
    const wrapperRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();

    /** Позиционирование dropdown селекта */
    useEffect(() => {
        let ADDITIONAL_ELEMENTS_HEIGHT = 60;

        if (showFilter) {
            ADDITIONAL_ELEMENTS_HEIGHT += 50;
        }

        if (mode === 'multi') {
            ADDITIONAL_ELEMENTS_HEIGHT += 50;
        }

        const setData = (position: Position, offset: number, listHeight: number) => {
            setListMaxHeight(listHeight);
            setPosition(position);
            setOffset(offset);
        };

        const calculateNewHeight = () => {
            /** Если не было клика, то ставим дефолтные настройки */
            if (!selectButtonClickPosition) return setData('below', 5, LIST_MAX_HEIGHT);

            /** Допустимая высота от клика до края окна */
            const availableBottomHeight = window.innerHeight - selectButtonClickPosition - ADDITIONAL_ELEMENTS_HEIGHT;

            /** Если расстояния снизу достаточно то ставим дефолтные настройки */
            if (availableBottomHeight > LIST_MAX_HEIGHT) return setData('below', 5, LIST_MAX_HEIGHT);

            /** Если расстояние сверху больше чем Максимально допустимая высота селекта, то поднимаем селект наверх */
            if (LIST_MAX_HEIGHT + ADDITIONAL_ELEMENTS_HEIGHT < selectButtonClickPosition)
                return setData('above', 15, LIST_MAX_HEIGHT);

            const availableTopHeight = selectButtonClickPosition - ADDITIONAL_ELEMENTS_HEIGHT;

            /** Если посчитанная высота  меньше минимальной, то обновляем высоту селекта на допустимую по нижнему краю
             * и ставим селект в нижнее положение
             */
            if (availableTopHeight < LIST_MIN_HEIGHT) {
                return setData('below', 5, availableBottomHeight);
            }
            /** Установка селекта в верхнее положение*/
            return setData('above', 15, availableTopHeight);
        };
        calculateNewHeight();
    }, [selectButtonClickPosition, showFilter, mode]);

    // Хук для самоочистки невалидных выбранных опций
    useEffect(() => {
        if (outsideSelected?.length && options.length) {
            let nonValidSelected: ISelectedOption[] = [];

            if (isSimple(options)) {
                const allOptionsIds = options.map((item) => item.id);
                nonValidSelected.push(...outsideSelected.filter((item) => !allOptionsIds.includes(item.id)));
            } else {
                const allOptionsIds = options.reduce((acc, section) => {
                    const ids = section.objects.map((item) => item.id);
                    return [...acc, ...ids];
                }, []);
                nonValidSelected.push(...outsideSelected.filter((item) => !allOptionsIds.includes(item.id)));
            }
            if (nonValidSelected.length) {
                const newSelected = outsideSelected.filter(
                    (item) => !nonValidSelected.map((non) => non.id).includes(item.id),
                );

                if (!newSelected.length && minSelected > 0) {
                    if (isSimple(options)) {
                        newSelected.push(options[0] as ISelectedOption);
                    } else {
                        newSelected.push(options[0].objects[0] as ISelectedOption);
                    }
                }

                handleSelect && handleSelect([...newSelected]);
            }
        }
    }, [JSON.stringify(outsideSelected), JSON.stringify(options), mode]);

    /**
     * Если были переданы внешние выбранные параметры,
     * то записываем их в локальное состояние
     */
    useEffect(() => {
        outsideSelected && setSelectedOptions([...outsideSelected]);
    }, [JSON.stringify(outsideSelected)]);

    /**
     * Если был передал внешний параметр закрытия дропдауна,
     * то закрытие происходит при его изменении
     */
    useEffect(() => {
        if (!showDropdown) return;
        outsideCloseDropdown.close && setShowDropdown(() => false);
    }, [outsideCloseDropdown]);

    /**
     * Как только открывается дропдаун,
     * пишем текущий стейт во временный
     */
    useEffect(() => {
        if (showDropdown && outsideSelected) {
            setTempSelectedOptions(() => outsideSelected);
            generateSelector(testId.split('_')[1]);
        }
    }, [showDropdown, testId, outsideSelected]);

    /**
     * Сортировка опций селекта
     */
    useEffect(() => {
        const sortedOptions = sortOptions({
            sortingConfig,
            options,
        });
        setSortedOptions(sortedOptions);
    }, [sortingConfig, JSON.stringify(options)]);

    const toggleDropdown = (e: React.MouseEvent) => {
        setSelectButtonClickPosition(e.clientY);

        setShowDropdown(() => !showDropdown);
    };

    const closeDropdown = () => {
        setSelectedOptions(() => tempSelectedOptions);
        setShowDropdown(false);
    };

    const onSortClick = (section: TSortingConfigSection) => () => {
        setSortingConfig((prevState) => getSortingConfig({ prevState, section }));
    };

    const onFilterChange = (args: { value: string }) => {
        setFilter(() => args.value);
    };

    const onTrashClick = (id: string) => {
        const newSelectedOptions = selectedOptions?.filter((item) => item.section !== id);
        setSelectedOptions(newSelectedOptions);
    };

    const onCheck =
        ({ option, section }: { option: IObject; section: string }) =>
        (args: { id?: string | undefined; checked: boolean }) => {
            const index = selectedOptions.findIndex((item) => item.id === option.id);
            const clone = cloneDeep(selectedOptions);

            if (index !== -1 && !args.checked) {
                clone.splice(index, 1);
                setSelectedOptions(clone);
            } else {
                // Если выбрано максимальное количество опций, то удаляем первую
                if (maxSelected && selectedOptions.length >= maxSelected && args.checked) {
                    clone.splice(0, 1);
                    setSelectedOptions([...clone, { ...option, section }]);
                } else {
                    setSelectedOptions([...selectedOptions, { ...option, section }]);
                }
            }
            handleCheck && handleCheck({ ...option, section, checked: args.checked });
        };

    const onSelectSingle =
        ({ option, section }: { option: IObject; section: string }) =>
        () => {
            !outsideSelected && setSelectedOptions([{ ...option, section }]);
            setShowDropdown(() => false);
            handleSelect && handleSelect([{ ...option, section }]);
        };

    const onSelectEntireSection = (section: string) => (value: { checked: boolean }) => {
        if (value.checked) {
            const newSelectedOptions = (sortedOptions as TNestedOptions)
                .find((item) => item.section === section)
                ?.objects.map((element) => ({ ...element, section }));
            if (newSelectedOptions) {
                setSelectedOptions((prevState) => {
                    const filteredPrev = prevState.filter((item) => item.section !== section);
                    return [...filteredPrev, ...newSelectedOptions];
                });
            }
        } else {
            onTrashClick(section);
        }
    };

    const list = useMemo(() => {
        if (mode === 'single' && isSimple(sortedOptions)) {
            const simpleOptions = sortedOptions as IObject[];
            const filtered = getFilteredOptions(simpleOptions, filter) as IObject[];
            return filtered.map((option) => {
                const selected =
                    selectedOptions?.find((item) => {
                        return String(item.id) === String(option.id);
                    }) !== undefined;
                return (
                    <OptionWrapper
                        key={`${option.text}==${option.id}`}
                        onClick={onSelectSingle({ option, section: '' })}
                        selected={selected}
                        data-test-id={option.id}
                    >
                        {option.text}
                    </OptionWrapper>
                );
            });
        } else if (isSimple(sortedOptions)) {
            const simpleOptions = sortedOptions as IObject[];
            const filtered = getFilteredOptions(simpleOptions, filter) as IObject[];
            return filtered.map((option, index) => {
                const selected = selectedOptions?.find((item) => item.id === option.id) !== undefined;
                let disabled = minSelected ? selected && selectedOptions.length <= minSelected : false;
                // if (maxSelected && selectedOptions.length >= maxSelected && !selected) {
                //     disabled = true;
                // }
                return (
                    <CheckBoxWrapper key={`${option.text}==${option.id}`}>
                        <Checkbox
                            label={option.text}
                            handleToggle={onCheck({ option, section: '' })}
                            id={option.id as string}
                            selected={selected}
                            disabled={disabled}
                        />
                    </CheckBoxWrapper>
                );
            });
        } else if (isNested(sortedOptions)) {
            const nestedOptions = sortedOptions as TNestedOptions;
            const filteredOptions = getFilteredOptions(nestedOptions, filter) as TNestedOptions;

            return filteredOptions.map((item, i) => {
                const list = item.objects.map((option, j) => {
                    const selected = selectedOptions?.find((obj) => obj.id === option.id) !== undefined;

                    let disabled = minSelected ? selected && selectedOptions.length <= minSelected : false;

                    // if (maxSelected && selectedOptions.length >= maxSelected && !selected) {
                    //     disabled = true;
                    // }

                    if (selected) disabled = false;
                    return (
                        <CheckBoxWrapper key={`${option.text}==${option.id}`}>
                            <Checkbox
                                label={option.text}
                                handleToggle={onCheck({ option, section: item.section })}
                                id={option.id as string}
                                selected={selected}
                                disabled={disabled}
                            />
                        </CheckBoxWrapper>
                    );
                });

                const numberSelectedItemsOfSection = selectedOptions?.filter((obj) => {
                    return obj.section === item.section;
                }).length;

                const selectEntireSection = canSelectEntireSection && !maxSelected;
                const selectedObjectsIds = selectedOptions.map((element) => element.id);
                const isSectionSelected = item.objects.every((element) => selectedObjectsIds.includes(element.id));

                return (
                    <Stack key={`${item.section}`} className={'SelectCollapse'} alignItems={'flex-start'} gap={2}>
                        {selectEntireSection && (
                            <Checkbox selected={isSectionSelected} handleToggle={onSelectEntireSection(item.section)} />
                        )}
                        <CollapseWrapper selectEntireSection={selectEntireSection}>
                            <Collapse
                                id={item.section}
                                hover="background-none"
                                icon="chevron-right"
                                label={`${item.section} (${numberSelectedItemsOfSection}/${item.objects.length})`}
                                addClasses={`collapse_grow ${selectEntireSection ? '' : 'checkbox_padding-left'}`}
                                iconPosition="right"
                                iconJustify="space-between"
                                additionalControl={
                                    <AdditionalControlsWrapper>
                                        <div style={{ display: 'flex' }} onClick={() => onTrashClick(item.section)}>
                                            {additionalControl}
                                        </div>
                                        {showSorting && <SortIcon onClick={onSortClick(item.section)} />}
                                    </AdditionalControlsWrapper>
                                }
                            >
                                {list}
                            </Collapse>
                        </CollapseWrapper>
                    </Stack>
                );
            });
        } else return [];
    }, [sortedOptions, filter, selectedOptions, mode, outsideSelected]);

    const onSaveButtonClick = () => {
        handleSelect && handleSelect(selectedOptions);
        setShowDropdown(() => false);
    };

    const handleFilterAdditionalControlClick = () => {
        onAdditionalFilterControlClick && onAdditionalFilterControlClick();
    };

    let singleModeText = text;

    if (mode === 'single' && outsideText !== null) {
        singleModeText = outsideText;
    } else if (mode === 'single') {
        if (Object.keys(selectedOptions)?.length) {
            singleModeText = Object.keys(selectedOptions)?.map((key) => {
                return selectedOptions[key].text;
            })[0];
        }
    }

    const className = React.useMemo(() => {
        return cn(['btn btn-bordered-primary text-left btn-selected', ...mergedStyles]);
    }, [mergedStyles]);

    const onSelectAllClick = () => {
        const filtered = getPlainFilteredOptions(options, filter);
        setSelectedOptions(filtered);
    };

    const onClearAllClick = () => {
        setSelectedOptions([]);
    };

    const dropDown = (
        <StDropdown width={dropdownWidth}>
            {showFilter && (
                <SearchWrapper>
                    <FilterWrapper>
                        <Search
                            outsideValue={filter}
                            handleChange={onFilterChange}
                            placeholder={t('Filter')}
                            fullWidth={true}
                        />
                    </FilterWrapper>
                    {isSimple(sortedOptions) && showSorting && <SortIcon onClick={onSortClick('NoSection')} />}
                    {additionalFilterControl && (
                        <div onClick={handleFilterAdditionalControlClick}>{additionalFilterControl}</div>
                    )}
                </SearchWrapper>
            )}
            <List data-test-id-wrapper={`list:settings`} maxHeight={listMaxHeight} mode={mode}>
                {list}
            </List>
            {mode === 'multi' && (
                <Footer>
                    {showClearAll && (
                        <SelectAllClearAll
                            optionsNumber={list.length}
                            selectedNumber={selectedOptions.length}
                            onSelectAllClick={onSelectAllClick}
                            onClearAllClick={onClearAllClick}
                        />
                    )}
                    <ButtonWrapper>
                        <Button
                            appearance="primary"
                            disabled={selectedOptions.length < minSelected}
                            // size="small"
                            style={{ marginTop: theme.spaces.xxs }}
                            onClick={onSaveButtonClick}
                            testId="SaveSelectButton"
                        >
                            {t(buttonText)}
                        </Button>
                    </ButtonWrapper>
                </Footer>
            )}
        </StDropdown>
    );
    const handleOpenChange: PopoverProps['onOpenChange'] = (e, data) => {
        if (!data.open) {
            closeDropdown();
        }
        // setShowDropdown(data.open || false);
    };

    return (
        <SelectWrapper maxWidth={maxWidth} disabled={disabled} ref={wrapperRef} fullWidth={fullWidth}>
            <div className={'filters-form__item-label'}>{labelText}</div>
            {Object.keys(selectedOptions).length && mode === 'multi' && !showDropdown ? (
                <Tooltip
                    content={
                        <>
                            {selectedOptions.map((obj) => {
                                const section = obj.section ? `${obj.section}: ` : '';
                                return (
                                    <SelectTooltipItem key={obj.id}>
                                        <span>{`${section}${obj.text}`}</span>
                                        <br />
                                    </SelectTooltipItem>
                                );
                            })}
                        </>
                    }
                    gap={5}
                >
                    <SelectButton
                        data-test-id={testId}
                        fullWidth={fullWidth}
                        className={className}
                        onClick={toggleDropdown}
                        disabled={disabled}
                        isRtl={document.dir === 'rtl'}
                        style={styles}
                    >
                        <TextWrapper isRtl={document.dir === 'rtl'}>
                            <span>{text}</span>
                            {mode === 'multi' && showSelectedOptionsCount && (
                                <span>{`: ${Object.keys(selectedOptions)?.length}`}</span>
                            )}
                        </TextWrapper>
                        {iconType && (
                            <IconWrapper isRtl={document.dir === 'rtl'}>
                                <Icon size="xxs" type={iconType} color={props.color} />
                            </IconWrapper>
                        )}
                    </SelectButton>
                </Tooltip>
            ) : (
                <Popover
                    open={showDropdown}
                    onOpenChange={handleOpenChange}
                    positioning={{ align: 'start', offset, position }}
                    closeOnScroll={true}
                >
                    <PopoverTrigger disableButtonEnhancement>
                        <SelectButton
                            data-test-id={testId}
                            isRtl={document.dir === 'rtl'}
                            fullWidth={fullWidth}
                            className={className}
                            onClick={toggleDropdown}
                            disabled={disabled}
                            style={styles}
                        >
                            <TextWrapper isRtl={document.dir === 'rtl'}>
                                <span>{singleModeText}</span>
                                {mode === 'multi' && showSelectedOptionsCount && (
                                    <span>{`: ${Object.keys(selectedOptions)?.length}`}</span>
                                )}
                            </TextWrapper>
                            {iconType && (
                                <IconWrapper isRtl={document.dir === 'rtl'}>
                                    <Icon size="xxs" type={iconType} color={props.color} />
                                </IconWrapper>
                            )}
                        </SelectButton>
                    </PopoverTrigger>
                    <PopoverSurface key={listMaxHeight}>{dropDown}</PopoverSurface>
                </Popover>
            )}
        </SelectWrapper>
    );
};

export default Select;
