import styled from 'styled-components/macro';

export const ChartWrapper = styled.div`
    margin-top: var(--space-sm);
`;

export const SelectsWrapper = styled.div`
    @media (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        margin-top: var(--space-sm);
    }
`;

export const LoaderWrapper = styled.div`
    margin-top: var(--space-sm);
`;

export const DynamicsChartTitle = styled.div`
    color: var(--color-primary-darker);
    /* color: var(--color-primary-light); */
    font-size: 1.25rem;
    /* display: inline-block; */
    font-weight: 500;
    /* height: 40px; */
`;

export const DynamicsSettingsWrapper = styled.div`
    position: absolute;
    top: 15px;
    right: 10px;
    background-color: white;
`;